import "alpinejs";
import "./confetti";

const setFreeDonation = function setFreeDonation(value) {
  if (value < 1) {
    value = null;
  }
  this.donations[3] = {
    ...this.donations[3],
    value: value,
    text: value ? `${value} €` : "Kita",
  };
  this.activeDonation = this.donations[3];
};

const copy = function copy() {
  const temp = document.createElement("input"),
    url = window.location.origin;

  document.body.appendChild(temp);
  temp.value = url;
  temp.select();
  document.execCommand("copy");
  document.body.removeChild(temp);
  document.getElementById("copyMsg").classList.add("fade");
  setTimeout(
    () => document.getElementById("copyMsg").classList.remove("fade"),
    5000
  );
};

const validate = function validate() {
  if (this.activeDonation.value !== null) {
    this.tab = 2;
  } else {
    this.isValid = false;
  }
};
window.moveBg = function () {
  const movementStrength = 25;
  const height = movementStrength / window.innerHeight;
  const width = movementStrength / (window.innerWidth / 2);
  const bg = document.getElementsByClassName("img");
  Array.from(bg).forEach((b) =>
    b.addEventListener("mousemove", function (e) {
      const pageX = e.pageX - window.innerWidth / 2;
      const pageY = e.pageY - window.innerHeight / 2;
      const newvalueX = width * pageX * -1;
      const newvalueY = height * pageY * -1;
      b.style.left = `${newvalueX}px`;
      b.style.top = `${newvalueY}px`;
    })
  );
};

window.init = function () {
  window.moveBg();
  window.confettiBtn();
};

window.initThankU = function () {
  window.confettiBtn();
};

window.data = function () {
  const donations = [
    {
      id: 0,
      text: "5 €",
      value: 5,
    },
    {
      id: 1,
      text: "10 €",
      value: 10,
    },
    {
      id: 2,
      text: "15 €",
      value: 15,
    },
    {
      id: 3,
      text: "Kita",
      value: null,
    },
  ];
  return {
    activePayment: "opay",
    activeDonation: donations[0],
    copy: copy,
    validate: validate,
    donations: donations,
    edit: false,
    setFreeDonation: setFreeDonation,
    tab: 1,
    isValid: true
  };
};
