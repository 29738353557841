// File#: _1_confetti-button
// Usage: codyhouse.co/license
window.confettiBtn = function () {
  const ConfettiBtn = function (element) {
    this.element = element;
    this.btn = this.element.getElementsByClassName("confetti-btn__btn");
    this.icon = this.element.getElementsByClassName("confetti-btn__icon");
    this.animating = false;
    this.animationClass = "confetti-btn--animate";
    initConfettiBtn(this);
  };

  function initConfettiBtn(element) {
    if (element.btn.length < 1 || element.icon.length < 1) return;
    element.btn[0].addEventListener("click", function (event) {
      if (element.animating) return;
      element.animating = true;
      setAnimationPosition(element, event);
      element.element.classList.add(element.animationClass);
      resetAnimation(element);
    });
  }

  function setAnimationPosition(element, event) {
    // change icon position based on click position
    const btnBoundingRect = element.btn[0].getBoundingClientRect();
    element.icon[0].style.setProperty(
      "left",
      event.clientX - btnBoundingRect.left - 10 + "px"
    );
    element.icon[0].style.setProperty(
      "top",
      event.clientY - btnBoundingRect.top - 10 + "px"
    );
  }

  function resetAnimation(element) {
    // reset the button at the end of the icon animation

    element.icon[0].addEventListener("animationend", function cb() {
      element.icon[0].removeEventListener("animationend", cb);
      element.element.classList.remove(element.animationClass);
      element.animating = false;
    });
  }

  function getElements() {
    const confettiBtn = document.getElementsByClassName("confetti-btn");
    if (confettiBtn.length > 0) {
      for (var i = 0; i < confettiBtn.length; i++) {
        (function (i) {
          new ConfettiBtn(confettiBtn[i]);
        })(i);
      }
    }
  }

  window.addEventListener("load", getElements)
  window.addEventListener("DOMNodeInserted", getElements)
};
